<template>
    <div class="bg-gray-400/50 absolute top-0 h-screen w-screen flex justify-center overflow-y-auto" id="modal-container z-10">
        <div class="w-[100vw] sm:w-[40rem] bg-white my-10 h-fit rounded-md p-2 text-black" ref="conversationModalRef" id="modal">
            <div class="flex justify-between items-center px-4 py-5">
                <p class="text-2xl">Conversation</p>
                <XMarkIcon class="h-6 w-6 text-gray-500 hover:text-gray-800 cursor-pointer"
                    @click="store.hideConversationModal" />
            </div>
            <hr>
            <table class="min-w-full divide-y divide-gray-300 text-left">
                <thead>
                    <tr>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">With
                        </th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                    </tr>
                </thead>
                <tbody class="bg-white">
                    <tr class="even:bg-gray-50" v-for="user in store.activeRoomList" :key="user">
                        <td class="flex items-center cursor-pointer whitespace-nowrap py-2 my-4 pl-4 text-sm font-medium text-gray-900 sm:pl-3 rounded-full border border-black bg-gradient-to-t from-gray-400 to-white"
                            v-if="user.type == 'user'" @click="showConversationUserMessage(user.name)">
                            <svg style="color: rgb(0, 0, 0);" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                fill="currentColor" class="bi bi-gender-male mr-2" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
                                    fill="#000"></path>
                            </svg>
                            <p>{{ user.name }}</p>
                            <p v-if="user.updatedAt && store.tabNotification[user.name]?.length" class="text-red-600 ml-auto mr-2">{{ getTimePassed(user.updatedAt) }}</p>
                        </td>
                        <td class=" px-3 py-4 text-sm text-gray-500" v-if="user.type == 'user'">
                            <div class="flex">
                                <button class="px-2 py-1 sm:px-6 mx-1 bg-custom-secondary text-white rounded-md flex items-center"
                                    @click="showConversationUserMessage(user.name)">
                                    <EnvelopeIcon class="h-6 w-6" />
                                    <p class="hidden sm:block">Show</p>
                                </button>
                                <button class="px-2 py-1 sm:px-6 mx-1 bg-custom-secondary text-white rounded-md flex items-center">
                                    <MagnifyingGlassIcon class="h-6 w-6" />
                                    <p class="hidden sm:block">Profile</p>
                                </button>
                                <button class="px-2 py-1 sm:px-6 mx-1 bg-custom-secondary text-white rounded-md flex items-center"
                                    @click="hideUser(user)">
                                    <TrashIcon class="h-6 w-6" />
                                    <p class="hidden sm:block">Hide</p>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <div class="text-right my-4">
                <button class="bg-custom-secondary p-1 px-4 rounded-md text-white mx-1"
                    @click="hideAllUser(store.activeRoomList)">Hide All</button>
                <button class="bg-custom-secondary p-1 px-4 rounded-md text-white mx-1" @click="showOption()">Option</button>
                <button class="bg-custom-secondary p-1 px-4 rounded-md text-white mx-1"
                    @click="store.hideConversationModal">Close</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { join } from '@/irc/client';
import { useMainStore } from '@/mainStore';
import { XMarkIcon, EnvelopeIcon, MagnifyingGlassIcon, TrashIcon } from '@heroicons/vue/24/solid';
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';
import { getTimePassed } from "@/helpers"

const store = useMainStore();
const conversationModalRef = ref(null);

onClickOutside(conversationModalRef, store.hideConversationModal);

const showConversationUserMessage = (user) => {
    store.hideConversationModal();
    store.setActiveRoom(user);    
    store.tabNotification[user] = [];
}

const hideUser = (user) => {
    store.removeRoomList(user)
    if (store.activeRoomList[0]) {
        join(store.activeRoomList[0].name);
    } else {
        store.showRoomList();
        store.setActiveRoom('')
    }
}

const hideAllUser = (users) => {
    
    const usersToRemove = users.filter(user => user.type === 'user');

    usersToRemove.forEach(user => {
        store.removeRoomList(user);
    });

    if(store.activeRoomList[0]) {
        join(store.activeRoomList[0].name);
    } else {
            store.setActiveRoom('')
            store.hideConversationModal();
            store.showRoomList();
    }
}

const showOption = () => {
    store.hideConversationModal();
    store.showOptionModal();
}

</script>