/* eslint-disable no-control-regex */
/* eslint-disable no-unused-vars */

import { onMounted, onUnmounted, ref } from 'vue';
import { emojis } from './constants/emoji';
import { LINK_CLASS, QUERY_EMBED, ROOM_CLASS } from './constants';

export const isScrollBottom = (element) => Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) <= 5;

// Maps the IRC colours mIRC uses to a html value
const ircColourMap = {
    "00": "#ffffff",
    "01": "#000000",
    "02": "#00007f",
    "03": "#009300",
    "04": "#ff0000",
    "05": "#7f0000",
    "06": "#9c009c",
    "07": "#fc7f00",
    "08": "#ffff00",
    "09": "#00fc00",
    "10": "#009393",
    "11": "#00ffff",
    "12": "#0000fc",
    "13": "#ff00ff",
    "14": "#7f7f7f",
    "15": "#d2d2d2",
};

// IRC Color parser
const ircColourNames = [
    "White","Black","Blue","Green","Red","Brown","Magenta","Orange","Yellow","Light Green", "Cyan","Light Cyan","Light Blue", "Pink", "Grey", "Light Grey"
];
const ircColourRegex = /\x03(\d{1,2})(,\d{1,2})?(.*?)(?=\x03|$)/g; 
const ircRevertRegex = new RegExp("\x0f", "g");
const ircStyleChars = ["\x02", "\x1d", "\x1f", "\x1e"]; 
const styleTags = ["<b>", "<i>", "<u>", "<s>"];
const styleClosures = ["</b>", "</i>", "</u>", "</s>"];

const escapeHtml = (text) => {
    const entities = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;'
    };
 
    return text.replace(/[&<>"']/g, match => entities[match]);
}
 
const hexToRgb = (hex) => {
    hex = hex.replace('#', '');
 
    // Convert the hex values to RGB
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
 
    return `rgb(${r}, ${g}, ${b})`;
}

export const parseIRCColours = (text) => {
    let insideStyles = new Array(ircStyleChars.length).fill(false);
    const applyStyles = (content) => {
        let styledContent = content;
        for (let i = 0; i < ircStyleChars.length; i++) {
            if (insideStyles[i]) styledContent += styleClosures[i];
        }
        return styledContent;
    };
 
    // remove any style revert chars (ctrl +o) since we don't support it.
    text = text.replace(ircRevertRegex, '');
 
    let htmlText = text.replace(ircColourRegex, (match, textColourCode, backgroundColourCode, content) => {
        const cssStyle = [];
        if (textColourCode) {
            let fgColour = textColourCode;
            if (!fgColour.startsWith("0") && parseInt(fgColour) < 10) fgColour = `0${fgColour}`;
            const textStyle = ircColourMap[fgColour] || "";
            cssStyle.push(`color: ${hexToRgb(textStyle)};`);
        }
        if (backgroundColourCode) {
            let bgColour = backgroundColourCode.substring(1);
            if (!bgColour.startsWith("0") && parseInt(bgColour) < 10) bgColour = `0${bgColour}`;
            const backgroundColourStyle = ircColourMap[bgColour] || "";
            cssStyle.push(`background: ${hexToRgb(backgroundColourStyle)};`);
        }
        return `<span style="${cssStyle.join('')}">${applyStyles(escapeHtml(content))}</span>`;
    });
 
    for (let i = 0; i < ircStyleChars.length; i++) {
        htmlText = htmlText.replace(new RegExp(ircStyleChars[i], "g"), () => {
            insideStyles[i] = !insideStyles[i];
            return insideStyles[i] ? styleTags[i] : styleClosures[i];
        });
    }
 
    htmlText = applyStyles(htmlText); // Apply any remaining style closures
    htmlText = htmlText.replace(new RegExp('\x03', 'g'), ''); // Remove any remaining colour codes
    return htmlText;
}

export const generateUniqueColorFromText = (text) => {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
        hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

export const useVisibilityChange = (onHidden, onVisible) => {
    const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
            onHidden();
        } else {
            onVisible();
        }
    };

    onMounted(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
    });

    onUnmounted(() => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
    });
}

// notification.js or inside useVisibility.js
export const showNotification = (msg) => {
    if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
        const notification = new Notification(msg);
        notification.onclick = function() {
            window.focus(); // This will focus the tab if the browser supports it
            this.close(); // Closes the notification
        };
    } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(permission => {
            if (permission === "granted") {
                const notification = new Notification(msg);
                notification.onclick = function() {
                    window.focus(); 
                    this.close(); 
                };
            }
        });
    }
}

export const checkNameSameWithModerator = (aName, bName) => {
    return aName === bName || 
           ('@' + aName) === bName || 
           ('+' + aName) === bName;
}

export const isModerator = (name) => {
    return name.startsWith('@') || name.startsWith('+')
}

export const isModUser = (store) => {
    return store.sidebarUserList[store.activeRoom]?.some((user) => user.userName === ('@' + store.userName) || user.userName === ('+' + store.userName))
}

export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function unicodeToEmoji(unicode) {
  return unicode
    .split('-')
    .map((hex) => parseInt(hex, 16))
    .map((hex) => String.fromCodePoint(hex))
    .join('')
}


export const convertStringWithEmoji = (str) => {
    let ret = "";
    const chunks = str.split(":");
    let isLastEmoji = false;
    for (let i = 0; i < chunks.length; i++) {
        if (i === 0) {
            ret += chunks[i]
        } else if (i === chunks.length - 1) {
            ret += isLastEmoji ? chunks[i] : ":" + chunks[i];
            isLastEmoji = false;
        } else {
            const emoji = emojis.find((emoji) => emoji.n.some((v) => v === chunks[i]))
            if (emoji) {
                // ret += emoji.u;
                ret += unicodeToEmoji(emoji.u)
                isLastEmoji = true;
            } else {
                ret += isLastEmoji ? chunks[i] : ":" + chunks[i];
                isLastEmoji = false;
            }
        }
    }
    return ret;
}

export const parseRoom = (str, roomList) => {
    let _str = str;
    roomList?.forEach((room) => {
        const regEx = new RegExp(room.name, "ig");
        _str = _str.replace(regEx, `<span class="${ROOM_CLASS} cursor-pointer text-main font-bold">${room.name}</span>`)
    })
    return _str;
}

export const urlify = (str) => {
  // Regular expression to find URLs in the text
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    // Replace URLs in the text with anchor tags
    // return str.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
    return str.replace(urlPattern, `<span class="${LINK_CLASS} cursor-pointer text-main">$1</span>`)
}

export const convertYoutubeAsEmbedded = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    const videoId = (match && match[2].length === 11)
      ? match[2]
        : null;
    
    if (videoId) 
        return `https://www.youtube.com/embed/${videoId}`
    return url;
}

export const extractYoutubeUrls = (str) => {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    return str.match(urlPattern)?.filter((url) => isYoutubeUrl(url)).map((url) => convertYoutubeAsEmbedded(url));
}

export const isEmbedImageUrl = (url) => {
    // Regular expression to match common image extensions, even with query parameters
    const imagePattern = /\.(jpg|jpeg|png|gif|bmp|webp)(\?.*)?$/i;
    if (imagePattern.test(url)) {
        const _url = new URL(url);
        return _url.searchParams.get(QUERY_EMBED) === 'true'
    } 
    return false;
}

export const isYoutubeUrl = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    const videoId = (match && match[2].length === 11)
      ? match[2]
        : null;
    
    return !!videoId
}
  // Get from local storage then
  // parse stored json or return initialValue
export const readLocalStorage = (key) => {
    // Prevent build error "window is undefined" but keep working
    if (typeof window === 'undefined') {
      return null
    }

    try {
      const item = window.localStorage.getItem(key)
      return item
    } catch (error) {
      console.warn(`Error reading localStorage key "${key}":`, error)
      return null
    }
}

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
export const writeLocalStorage = (key, value) => {
    try {
      // Save to local storage
      window.localStorage.setItem(key, value)
    } catch (error) {
      console.warn(`Error setting localStorage key "${key}":`, error)
    }
}

export const getTimePassed = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);
    const seconds = Math.floor((now - date) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
        return `${years}y ago`;
    } else if (months > 0) {
        return `${months}mo ago`;
    } else if (days > 0) {
        return `${days}d ago`;
    } else if (hours > 0) {
        return `${hours}h ago`;
    } else if (minutes > 0) {
        return `${minutes}min ago`;
    } else {
        return 'just now';
    }
}
