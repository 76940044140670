const months = [
  { text: 'January', value: '01' },
  { text: 'February', value: '02' },
  { text: 'March', value: '03' },
  { text: 'April', value: '04' },
  { text: 'May', value: '05' },
  { text: 'June', value: '06' },
  { text: 'July', value: '07' },
  { text: 'August', value: '08' },
  { text: 'September', value: '09' },
  { text: 'October', value: '10' },
  { text: 'November', value: '11' },
  { text: 'December', value: '12' },
];

const MSG_TYPE = Object.freeze({
  MESSAGE:   Symbol("MESSAGE"),
  GIF: Symbol("GIF"),
  ACTION: Symbol("ACTION"),
  WHISPER: Symbol("WHISPER"),
  JOIN: Symbol("JOIN"),
  QUIT: Symbol("QUIT"),
  PART: Symbol("PART"),
  NOTICE: Symbol("NOTICE"),
  KICK: Symbol("KICK"),
  BAN: Symbol("BAN"),
  UNBAN: Symbol("UNBAN"),
  TOPIC: Symbol("TOPIC"),
  MODE: Symbol("MODE"),
  INVITE: Symbol("INVITE"),
  IGNORE: Symbol("IGNORE"),
  NICK: Symbol("NICK"),
});

const GENDER_MALE = "male";
const GENDER_FEMALE = "female";
const GENDER_OTHER = "others";

const genderColors = {
  [GENDER_MALE]: '#1299ff',
  [GENDER_FEMALE]: '#ff69b4',
  [GENDER_OTHER]: 'purple' 
}

const commands = [
  {
    command: '/me',
    format: "/me",
    description: 'Sends an action message in the third person.',
    unit: 2,
    type: MSG_TYPE.ACTION
  },
  {
    command: '/join',
    alias: '/j',
    format: "/join #channel",
    description: 'Joins a specific chat room (channel)',
    unit: 2,
    type: MSG_TYPE.JOIN
  },
  {
    command: '/part',
    alias: '/p',
    format: "/part #channel",
    description: 'Leaves the current chat room (channel).',
    unit: 2,
    type: MSG_TYPE.PART
  },
  {
    command: '/quit',
    alias: '/q',
    format: "/quit Goodbye!",
    description: 'Disconnects from server.',
    unit: 2,
    type: MSG_TYPE.QUIT
  },
  {
    command: '/whisper',
    alias: '/w',
    format: "/whisper User123 Hello!",
    description: 'Sends a private message to another user.',
    unit: 3,
    type: MSG_TYPE.WHISPER
  },
  {
    command: '/notice',
    alias: '/n',
    format: "/notice User123 Please read the rules.",
    description: 'Sends a notice message to another user or channel. Typically used for important messages.',
    unit: 3,
    type: MSG_TYPE.NOTICE
  },
  {
    command: '/kick',
    alias: '/k',
    format: "/kick User123 Spamming is not allowed.",
    description: 'Kicks a user from the current chat room (requires operator privileges).',
    unit: 3,
    type: MSG_TYPE.KICK,
    moderator: true
  },
  {
    command: '/ban',
    alias: '/b',
    format: "/ban User123",
    description: 'Bans a user from the current chat room (requires operator privileges).	',
    unit: 2,
    type: MSG_TYPE.BAN,
    moderator: true
  },
  {
    command: '/unban',
    // alias: '/b',
    format: "/unban User123",
    description: 'Recover a banned user from the current chat room (requires operator privileges).	',
    unit: 2,
    type: MSG_TYPE.UNBAN,
    moderator: true
  },
  {
    command: '/topic',
    alias: '/t',
    format: "/topic Welcome to the Teen Chat Room!",
    description: 'Changes the topic of the channel (requires operator privileges).	',
    unit: 2,
    type: MSG_TYPE.TOPIC,
    moderator: true
  },
  // {
  //   command: '/mode',
  //   alias: '/m',
  //   format: "/mode #channel +m",
  //   description: 'Changes the mode of the channel or a user (requires operator privileges).',
  //   unit: 3,
  //   type: MSG_TYPE.MODE,
  //   moderator: true
  // },
  {
    command: '/invite',
    format: "/invite User123 #channel",
    description: 'Invites another user to join a channel.',
    unit: 3,
    type: MSG_TYPE.INVITE
  },
  {
    command: '/ignore',
    format: "/ignore User123",
    description: 'Ignores messages from a specific user.',
    unit: 2,
    type: MSG_TYPE.IGNORE
  },
  {
    command: '/nick',
    format: "/nick User123",
    description: 'Changes your nickname.',
    unit: 2,
    type: MSG_TYPE.NICK,
    guest: true,
  },
]

const STORAGE_JOIN_USER = "join"
const STORAGE_QUIT_USER = "quit"
const STORAGE_FOREGROUND = "foreground"
const STORAGE_BACKGROUND = "background"

const LINK_CLASS = "link-in-chat";
const ROOM_CLASS = "room-in-chat";

const QUERY_EMBED = "embed";

export { months, genderColors, commands, MSG_TYPE, STORAGE_JOIN_USER, STORAGE_QUIT_USER, GENDER_MALE, GENDER_FEMALE, GENDER_OTHER, STORAGE_FOREGROUND, STORAGE_BACKGROUND, LINK_CLASS, ROOM_CLASS, QUERY_EMBED }