<template>
    <div class="w-full p-2 h-[50px] flex items-center" :style="{ backgroundColor: store.bgPrimary }">
        <img src="/img/logo.png" class="h-8" />
        <div class="nav-buttons flex md:gap-4 gap-1 md:ml-4 ml-1 overflow-x-auto">
            <NavButton @click="store.toggleMenuList()" id="menu-button">
                <Cog8ToothIcon class="h-6 w-6 text-custom-secondary" />
                <span class="text-sm text-custom-secondary hidden xl:block">Menu</span>
            </NavButton>
                <MenuOption v-if="store.menuList"/>
            <NavButton @click="store.showRoomList()" id="rooms-button">
                <Bars3Icon class="h-6 w-6 text-custom-secondary" />
                <span class="text-sm text-custom-secondary hidden xl:block">Rooms</span>
            </NavButton>
            <NavButton @click="store.showConversationModal">
                <div class="relative">
                  <EnvelopeIcon class="h-6 w-6 text-custom-secondary" />
                  <div v-if="hasConversation" class="absolute -right-1 top-0 w-2 h-2 rounded-full bg-main"></div>
                </div>
                <span class="text-sm text-custom-secondary hidden xl:block">Message</span>
            </NavButton>
            <NavButton @click="showHelpModal()">
                <QuestionMarkCircleIcon class="h-6 w-6 text-custom-secondary" />
                <span class="text-sm text-custom-secondary hidden xl:block">Help</span>
            </NavButton>
            <div v-if="store.expandActiveRoomList" id="rooms-list">
                <div class="flex gap-x-1">
                    <NavButton v-for="room in store.activeRoomList" :key="room" :class="[store.activeRoom == room.name ? 'border border-black mr-2' : 'mr-2']">
                        <div class="flex items-center text-custom-secondary" @click="joinRoom(room.name)">
                            <span class="text-sm  text-custom-secondary">{{ room.name }}</span>
                            <div class="ml-1 bg-red-600 px-1 rounded-md text-white" v-if="store.tabNotification[room.name].length > 0">{{ store.tabNotification[room.name].length }}</div>
                        </div>
                        <XMarkIcon class="h-6 w-6 text-custom-secondary" @click="removeList(room)"/>
                    </NavButton>
                </div>
            </div>
            <NavButton v-if="!store.expandActiveRoomList" @click="toggleBtwActiveRoomList()">
                <PlayIcon class="h-6 w-6 text-custom-secondary"/>
            </NavButton>
            <NavButton class="text-left md:hidden" v-if="store.sideBar" @click="store.hideSideBar">
                <XMarkIcon class="h-6 w-6 text-custom-secondary"/>
            </NavButton>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import NavButton from "./NavButton.vue";
import MenuOption from "./MenuOption.vue"
import { Bars3Icon, Cog8ToothIcon, XMarkIcon, EnvelopeIcon, QuestionMarkCircleIcon, PlayIcon } from '@heroicons/vue/24/solid'
import { useMainStore } from "@/mainStore";
import { join, quit } from "@/irc/client";
import Shepherd from 'shepherd.js';
const Tour = Shepherd.Tour;
import 'shepherd.js/dist/css/shepherd.css';

const tour = ref(null);
const store = useMainStore();

const hasConversation = computed(() => {
  const userRooms = store.activeRoomList.filter((room) => room.type === 'user')
  return userRooms.some((room) => store.tabNotification[room.name].length > 0)
})

function initializeTour() {
  tour.value = new Tour({
    useModalOverlay: true,
    defaultStepOptions: {
        cancelIcon: {
            enabled: true, // This enables the default cancel (close) icon
        },
    },
    steps: [
      {
        id: 'menuButtonTour',
        title: 'Menu',
        text: '<p class="mb-2">The "Menu" button will show you various useful links. </p><p class="mb-2">You can change some chat options or your font style that others will see.</p>',
        attachTo: {
          element: '#menu-button',
          on: 'bottom',
        },
        buttons: [
          {
            text: 'Next',
            action() {
              tour.value.next();
            },
          },
        ],
      },
      {
        id: 'roomsButtonTour',
        title: 'Rooms',
        text: '<p class="mb-2">The "Rooms" button shows you a list of all the rooms you are in. </p><p class="mb-2">Touch the "List" button to view other rooms on the server.</p><p class="mb-2">The "Messages" button is where all of your private conversations are managed.</p><p class="mb-2">A <span class="bg-red-600 font-bold text-white rounded-md">bubble</span> appears when you have unread messages!</p><p class="mb-2">The triangle wedge button quickly switches between your two most recent tabs.</p>',
        attachTo: {
          element: '#rooms-button',
          on: 'bottom',
        },
        buttons: [
          {
            text: 'Prev',
            action() {
              tour.value.back();
            },
          },
          {
            text: 'Next',
            action() {
              tour.value.next();
            },
          },
        ],
      },
      {
        id: 'roomsListTour',
        title: 'The "Leave/End close tebs"',
        text: '<p class="mb-2">(You <i>"leave"</i> rooms, <i>"end"</i> private conversations)</p><p class="mb-2">The current tab you are chatting to is labeled to the far right.</p>',
        attachTo: {
          element: '#rooms-list',
          on: 'bottom',
        },
        buttons: [
          {
            text: 'Prev',
            action() {
              tour.value.back();
            },
          },
          {
            text: 'Next',
            action() {
              tour.value.next();
            },
          },
        ],
      },
      {
        id: 'messageBoxTour',
        title: 'Messages',
        text: '<p class="mb-2">Messages sent by you or anyone else will appear in this main box.</p>',
        attachTo: {
            element: '#message-box',
            on: 'right'
        },
        buttons: [
          {
            text: 'Prev',
            action() {
              tour.value.back();
            },
          },
          {
            text: 'Next',
            action() {
              tour.value.next();
            },
          },
        ],
      },
      {
        id: 'sideBarTour',
        title: 'User List',
        text: '<p class="mb-2">In room tabs, a userlist will be available showing all users currently in the room.</p><p class="mb-2">You can touch on a name in the list to expand a menu for that person.</p><p class="mb-2">Staff members usually appear at the top of the list, and have symbols in front of their names.</p><p class="mb-2">Depending on the size of your device or your browser window, the userlist may be hidden automatically.</p><p class="mb-2">Touch the  <strong> in the lower right corner to show or hide the userlist.</strong></p>',
        attachTo: {
            element: '#side-bar',
            on: 'right'
        },
        buttons: [
          {
            text: 'Prev',
            action() {
              tour.value.back();
            },
          },
          {
            text: 'Next',
            action() {
              tour.value.next();
            },
          },
        ],
      },
      {
        id: 'gifIconTour',
        title: 'Gif Icon',
        text: '<p class="mb-2">Touch the <strong>Gif button</strong> to upload a Gif.</p>',
        attachTo: {
            element: '#gif-icon-button',
            on: 'top'
        },
        buttons: [
          {
            text: 'Prev',
            action() {
              tour.value.back();
            },
          },
          {
            text: 'Next',
            action() {
              tour.value.next();
            },
          },
        ],
      },
      {
        id: 'messageInputTour',
        title: 'Message Input',
        text: '<p class="mb-2">Type messages here to send something to the current tab.</p>',
        attachTo: {
            element: '#message',
            on: 'top'
        },
        buttons: [
          {
            text: 'Prev',
            action() {
              tour.value.back();
            },
          },
        ],
      }
      // Add more steps as needed
    ],
  });
}

const removeList = (room) => {
    store.removeRoomList(room)
    if(store.activeRoomList[0]) {
        join(store.activeRoomList[0].name);
    } else {
        store.showRoomList();
        store.setActiveRoom('')
    }
    quit(room.name);
}

const joinRoom = (room) => {
    join(room);
    store.hideRoomList();
    store.showMessageRoom();
    store.setActiveRoom(room);
    store.storeRoomName(room);
    store.tabNotification[room] = [];
    store.hideWhisperInput()
}

const toggleBtwActiveRoomList = () => {
    if(store.activeRoomList.length >= 2){
        if(store.activeRoomList[0].name == store.activeRoom){
            store.setActiveRoom(store.activeRoomList[1].name)
            join(store.activeRoomList[1].name)
        } else{
            store.setActiveRoom(store.activeRoomList[0].name)
            join(store.activeRoomList[0].name)
        }
    }
    store.hideWhisperInput()
}

const showHelpModal = () => {
    if (!tour.value) {
        initializeTour();
    }
    tour.value.start();
}
</script>
