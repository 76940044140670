<template>
  <div>
    <div @touchstart="grabVideoHolder" @touchend="releaseVideoHolder" @mousedown="grabVideoHolder"
      @mouseup="releaseVideoHolder" ref="videoContainerRef" :id="props.isRemote ? 'remote-video-holder-container' : 'video-holder-container'" :class="[isFullScreen ? 'full-video-holder' : 'video-holder', videoHolderGrabbed ? 'grabbed' : '']">
      <div v-if="!props.isRemote" class="absolute top-0 left-0 right-0 bg-gray-700/50 text-white">{{ store.privateBroadcasting ? "Private" : "Public" }}</div>
      <video ref="videoRef" muted :id="props.isRemote ? 'remote-video-holder' : 'local-video-holder'" playsinline autoplay></video>
      <div class="video-holder-actions">
        <div :class="isFullScreen ? 'absolute right-2 bottom-2 flex flex-col items-center gap-2' : 'flex justify-around pt-1 items-center text-center gap-2'">
          <div class="text-center" v-if="!props.isRemote">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-8 inline h-8 text-white">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <span class="text-white block">{{ store.peerCount }}</span>
          </div>
          <div class="text-center" v-if="!store.isAudioDisable">
            <!-- Mute -->
            <svg v-if="muted" @click="unMuteAudio" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" class="w-8 h-8 inline text-red cursor-pointer">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z" />
            </svg>

            <!-- Unmute -->
            <svg v-if="!muted" @click="muteAudio" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" class="w-8 h-8 inline text-white cursor-pointer">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
            </svg>


            <span class="text-white block">Audio</span>
          </div>
          <div class="text-center" v-if="!store.isVideoDisable">

            <!-- Enable camera -->
            <svg v-if="videoEnabled" @click="disableVideo" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              class="w-8 h-8 inline text-white cursor-pointer">
              <path stroke-linecap="round"
                d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
            </svg>


            <!-- Disable camera -->
            <svg v-if="!videoEnabled" @click="enableVideo" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 inline text-red cursor-pointer">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M12 18.75H4.5a2.25 2.25 0 01-2.25-2.25V9m12.841 9.091L16.5 19.5m-1.409-1.409c.407-.407.659-.97.659-1.591v-9a2.25 2.25 0 00-2.25-2.25h-9c-.621 0-1.184.252-1.591.659m12.182 12.182L2.909 5.909M1.5 4.5l1.409 1.409" />
            </svg>


            <span class="text-white block">Video</span>

          </div>
          <div class="text-center">
            <!-- Fullscreen -->
            <svg v-if="isFullScreen" @click="exitFullScreen" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              class="w-8 h-8 inline text-white cursor-pointer">
              <path d="M16.5831 8.18056H19.6386V9.70833H15.0553V5.125H16.5831V8.18056ZM8.94417 9.70833H4.36084V8.18056H7.4164V5.125H8.94417V9.70833ZM16.5831 15.8194V18.875H15.0553V14.2917H19.6386V15.8194H16.5831ZM8.94417 14.2917V18.875H7.4164V15.8194H4.36084V14.2917H8.94417Z"/>
            </svg>

            <!-- Exit fullscreen -->
            <svg v-else @click="enterFullScreen" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 inline text-white cursor-pointer">
              <path d="M18.1108 5.125H19.6386V9.70833H18.1108V6.65278H15.0553V5.125H18.1108ZM5.88862 5.125H8.94417V6.65278H5.88862V9.70833H4.36084V5.125H5.88862ZM18.1108 17.3472V14.2917H19.6386V18.875H15.0553V17.3472H18.1108ZM5.88862 17.3472H8.94417V18.875H4.36084V14.2917H5.88862V17.3472Z"/>
            </svg>

            <span class="text-white block">{{ isFullScreen ? 'Exit FullScreen' : 'Full'}}</span>

          </div>
        </div>
      </div>
      <div v-if="!props.isRemote" @click="stopBroadcast" class="video-close-btn">X</div>
      <div v-else @click="stopViewing" class="video-close-btn">X</div>
    </div>
  </div>
</template>


<style>

.full-video-holder {
  border: 2px solid #0000;
  background-color: #000099;
  position: fixed;
  top: 0% !important;
  left: 0% !important;
  right: 0%;
  bottom: 0%;
  z-index: 999;
  border-radius: 1rem;
  overflow: hidden;
}
.video-holder,
.remote-video-holder {
  width: 240px;
  border: 2px solid #0000;
  background-color: #000099;
  position: fixed;
  top: 62%;
  right: 25%;
  z-index: 999;
  border-radius: 1rem;
  overflow: hidden;
}

@media only screen and (min-width: 600px) {

  .video-holder,
  .remote-video-holder {
    right: 17%;
  }

}


.video-holder video,
.remote-video-holder video {
  border-radius: 1rem;
}

.video-holder-actions,
.remote-video-holder-actions {
  padding: 2px 5px;
}

.flex {
  display: flex;
}

.justify-around {
  justify-content: space-around;
}

.block {
  display: block;
}


.grabbed {
  cursor: move;
}

#local-video-holder,
#remote-video-holder {
  width: 100%;
  height: 100%;
}

.broadcast-icon {
  cursor: pointer;
  padding: 5px;
  border-radius: 20px;
  transition: 0.5s;
}

.broadcast-icon:hover {
  background-color: rgba(224, 224, 119, 0.5);
}

.text-red {
  color: red;
}

.video-close-btn {
  position: absolute;
  top: 4px;
  color: gray;
  font-size: 17px;
  right: 9px;
  cursor: pointer;
  transition: 1s;
}

.video-close-btn:hover {
  color: black;
}

.justify-between {
  justify-content: space-between;
}
</style>

<script setup>
import { ref, onMounted, defineProps } from 'vue'
import { useMainStore } from '@/mainStore';
import { stopBroadcasting } from '@/irc/broadcaster';
const props = defineProps(['isRemote'])
const store = useMainStore();

const videoHolderGrabbed = ref(false);
const grabPosition = ref({});
const muted = ref(true);
const videoEnabled = ref(true);
const isFullScreen = ref(false);
const videoRef = ref(null);
const videoContainerRef = ref(null);

onMounted(() => {
  if( !props.isRemote )
    store.setLocalVideo(videoContainerRef?.value);


  window.document.addEventListener("mousemove", dragHolder);
  window.document.addEventListener("touchmove", dragHolder);

  window.document.addEventListener("mouseup", releaseVideoHolder);
  window.document.addEventListener("touchend", releaseVideoHolder);
});

function dragHolder(e) {
  if (!isFullScreen.value && videoHolderGrabbed.value) {
    if (e.touches && e.touches.length > 0) {
      e = e.touches[0];
    }

    videoContainerRef.value.style.top =
      e.clientY + grabPosition.value.yDiff + "px";
    videoContainerRef.value.style.left =
      e.clientX + grabPosition.value.xDiff + "px";
  }
}

function releaseVideoHolder() {
  videoHolderGrabbed.value = false;
}

function grabVideoHolder(e) {
  if (e.touches && e.touches.length > 0) {
    e = e.touches[0];
  }

  videoHolderGrabbed.value = true;
  var bounds = videoContainerRef.value.getBoundingClientRect();
  grabPosition.value.yDiff = bounds.top - e.clientY;
  grabPosition.value.xDiff = bounds.left - e.clientX;
}

function stopBroadcast() {
  store.toggleBroadcaster();
  stopBroadcasting();
}

function stopViewing() {
    if (store.activeBroadcaster && store.peers[store.activeBroadcaster]) {
        store.peers[store.activeBroadcaster].close();
        store.removePeer(store.activeBroadcaster);
        store.deleteRemoteStream();
        store.removeActiveBroadcaster();
    }
}

function muteAudio() {
  try {
    videoRef.value.srcObject.getAudioTracks()[0].enabled = false;
    muted.value = true;
  } catch (e) {
    console.log(e)
  }
}

function unMuteAudio() {
  try {
    videoRef.value.srcObject.getAudioTracks()[0].enabled = true;
    muted.value = false;
  } catch (e) {
    console.log(e)
  }
}

function enableVideo() {
  try {
    videoRef.value.srcObject.getVideoTracks()[0].enabled = true;
    videoEnabled.value = true;
  } catch (e) {
    console.log(e)
  }
}

function disableVideo() {
  try {
    videoRef.value.srcObject.getVideoTracks()[0].enabled = false;
    videoEnabled.value = false;
  } catch (e) {
    console.log(e)
  }
}

function enterFullScreen() {
  isFullScreen.value = true;
}

function exitFullScreen() {
  isFullScreen.value = false;
}
</script>