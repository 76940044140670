<template>
    <div :class="{ '-top-64': store.navbarAtBottom, 'absolute bg-white top-14 shadow-popup rounded-xl w-48 text-left z-10': true }">
        <ul class="p-1" ref="menuOptionModal">
            <li class="flex items-center p-2 py-1 rounded-md my-1 border border-white hover:border hover:border-solid hover:border-blue-500 cursor-pointer"
                @click="showOptionValue">
                <WrenchIcon class="h-4 w-4 text-black mr-2" />
                <p>Options</p>
            </li>
            <li class="flex items-center p-2 py-1 rounded-md my-1 border border-white hover:border hover:border-solid hover:border-blue-500 cursor-pointer"
                @click="showFontValue">
                <svg height="20" width="20">
                    <text x="0" y="15" fill="black">A</text>
                </svg>
                <p>Fonts</p>
            </li>
            <hr class="border-t-1 border-t-gray-400">
            <li @click="onLogout(store.userName)"
                class="flex items-center p-2 py-1 rounded-md my-1 border border-white hover:border hover:border-solid hover:border-blue-500 cursor-pointer">
                <ArrowRightOnRectangleIcon class="h-4 w-4 text-black mr-2" />
                <p>Logout</p>
            </li>
            <a href="https://www.123freechat.com/legal/chat-rules/" target="_blank">
                <li
                    class="flex items-center p-2 py-1 rounded-md my-1 border border-white hover:border hover:border-solid hover:border-blue-500 cursor-pointer">
                    <StarIcon class="h-4 w-4 text-black mr-2" />
                    <p>Rules</p>
                </li>
            </a>
            <li @click="showReportIncident()"
                class="flex items-center p-2 py-1 rounded-md my-1 border border-white hover:border hover:border-solid hover:border-blue-500 cursor-pointer">
                <QuestionMarkCircleIcon class="h-4 w-4 text-black mr-2" />
                <p>Report Incident</p>
            </li>
            <a href="https://www.123freechat.com/">
                <li
                    class="flex items-center p-2 py-1 rounded-md my-1 border border-white hover:border hover:border-solid hover:border-blue-500 cursor-pointer">
                    <HeartIcon class="h-4 w-4 text-black mr-2" />
                    <p>Back to site</p>
                </li>
            </a>
        </ul>
    </div>
</template>

<script setup>
import { useMainStore } from '@/mainStore';
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue';
import { WrenchIcon, ArrowRightOnRectangleIcon, StarIcon, QuestionMarkCircleIcon, HeartIcon } from '@heroicons/vue/24/solid'
import { logout } from '@/irc/client';
const store = useMainStore();

const menuOptionModal = ref(null)

onClickOutside(menuOptionModal, store.hideMenuList);

const showOptionValue = () => {
    store.showOptionModal();
    store.hideMenuList();
}

const showFontValue = () => {
    store.showFontModal();
    store.hideMenuList();
}

const showReportIncident = () => {
    store.showReportIncidentModal();
    store.hideMenuList();
}

const onLogout = (name) => {
    logout(name);
}
</script>
