<template>
    <div class="bg-gray-400/50 absolute top-0 h-screen w-screen flex justify-center overflow-y-auto z-10"
        id="modal-container">
        <div class="w-[100vw] md:w-[30rem] bg-white my-40 h-fit rounded-md p-2 text-black" ref="gifModalRef" id="profileModal">
            <div class="flex justify-between items-center px-4 py-5">
                <p class="text-2xl">GIPHY</p>
                <XMarkIcon class="h-6 w-6 text-gray-500 hover:text-gray-800 cursor-pointer" @click="store.hideGifModal" />
            </div>
            <hr>
            <div class="text-left mx-4 my-4 flex flex-col">
                <form @submit.prevent>
                    <label for="searchGif" class="text-sm mb-2">Type to search GIFs</label>
                    <input type="text" name="searchGif" v-model="gifSearch" @input="handleGifSearch" class="border border-black rounded-md w-full px-2 py-1" placeholder="Search here">
                </form>
                <div class="grid grid-cols-4 gap-1 max-h-[200px] mt-2 overflow-y-auto">
                    <div v-for="gif in gifs" :key="gif">
                        <img @click="selectGif(gif.images.fixed_width_small.url)" :src="gif.images.fixed_width_small.url"
                            class="object-cover h-[100px]"
                            :class="[store.selectedGif == gif.images.fixed_width_small.url ? 'border-2 border-red-500 cursor-pointer' : 'inline hover:ring-2 ring-secondary cursor-pointer']" />
                    </div>
                </div>
                <div class="my-4">
                    <button
                        class="px-2 py-2 sm:px-6 mx-1 bg-custom-secondary text-white disabled:bg-yellow-500/20 disabled:text-black rounded-md flex items-center"
                        :disabled="store.selectedGif == ''" @click="sendGif()">Send</button>
                </div>
            </div>
            <hr>
            <div class="py-4">
                <button class="bg-custom-secondary w-full py-2 rounded-md text-white" @click="store.hideGifModal">Close</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useMainStore } from '@/mainStore';
import { onClickOutside } from '@vueuse/core';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { onMounted, ref } from 'vue';
import axios from 'axios';
import { sendMessage } from '@/irc/client';
import { MSG_TYPE } from '@/constants';

const store = useMainStore();
let gifModalRef = ref(null)
let gifSearch = ref("");
let gifs = ref([]);

const giphyApiKey = "fNsXPvpiNjfh3amWerzSpwkC5SsUmRpa";

onClickOutside(gifModalRef, store.hideGifModal);

const handleGifSearch = () => {
    axios.get(`https://api.giphy.com/v1/gifs/search?api_key=${giphyApiKey}&q=${gifSearch.value ? gifSearch.value : 'uh'}`).then(res => {
        if (res.status == 200) {
            gifs.value = res.data.data;
        }
    }).finally(() => {
        store.storeSelectedGif('');
    });
}

const sendGif = () => {
    sendMessage(MSG_TYPE.GIF, store.selectedGif, store.activeRoom)
    store.hideGifModal()
}

const selectGif = (gif) => {
    store.storeSelectedGif(gif)
}

onMounted(() => {
    handleGifSearch()
});

</script>
