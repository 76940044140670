<template>
  <div>
      <div class="bg-gray-400/50 absolute top-0 h-screen w-screen flex justify-center overflow-y-auto z-10" id="modal-container">
        <div class="bg-white my-10 h-fit rounded-md p-2 text-black" ref="warningModal" id="modal">
            <div class="text-left mb-2">
              <div class="flex justify-between">
                <h3 class="text-lg font-semibold leading-6 text-gray-900 mb-4">Warning</h3>
                <button @click="onClose">
                  <XMarkIcon class="h-6 w-6 text-gray-300 hover:text-gray-700" />
                </button>
              </div>
            </div>
            <hr>

            <p class="max-w-[480px]">
              You are about to visit an external site. This site is not affiliated with us, and we cannot guarantee its security. Do you wish to continue?
            </p>

            <div class="flex items-center justify-end gap-4">
              <div class="text-right mt-3" @click="onContinue">
                <button class="bg-custom-secondary p-2 px-4 text-white rounded-md">Continue</button>
              </div>
              <div class="text-right mt-3" @click="onClose">
                <button class="bg-green-700 p-2 px-4 text-white rounded-md">Close</button>
              </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script setup>
/* eslint-disable no-control-regex */
/* eslint-disable no-unused-vars */
import { useMainStore } from '@/mainStore';
import { onClickOutside } from '@vueuse/core'
import { ref, defineProps, defineEmits } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';

const props = defineProps(['url'])
const emit = defineEmits(['close'])
const store = useMainStore();

const warningModal = ref(null)

const onContinue = () => {
  store.addToWhitelistUrl(props.url);
  window.open(props.url, "_blank");
  onClose();
}

const onClose = () => {
  emit('close')
}

onClickOutside(warningModal, onClose);

</script>
