const replies = {
  ERR_ALREADYREGISTRED: "462",
  ERR_BADCHANMASK: "476",
  ERR_BADCHANNELKEY: "475",
  ERR_BADMASK: "415",
  ERR_BANLISTFULL: "478",
  ERR_BANNEDFROMCHAN: "474",
  ERR_CANNOTSENDTOCHAN: "404",
  ERR_CANTKILLSERVER: "483",
  ERR_CHANNELISFULL: "471",
  ERR_CHANOPRIVSNEEDED: "482",
  ERR_ERRONEOUSNICKNAME: "432",
  ERR_FILEERROR: "424",
  ERR_INVITEONLYCHAN: "473",
  ERR_KEYSET: "467",
  ERR_NEEDMOREPARAMS: "461",
  ERR_NICKCOLLISION: "436",
  ERR_NICKNAMEINUSE: "433",
  ERR_NOADMININFO: "423",
  ERR_NOCHANMODES: "477",
  ERR_NOLOGIN: "444",
  ERR_NOMOTD: "422",
  ERR_NONICKNAMEGIVEN: "431",
  ERR_NOOPERHOST: "491",
  ERR_NOORIGIN: "409",
  ERR_NOPERMFORHOST: "463",
  ERR_NOPRIVILEGES: "481",
  ERR_NORECIPIENT: "411",
  ERR_NOSERVICEHOST: "492",
  ERR_NOSUCHCHANNEL: "403",
  ERR_NOSUCHNICK: "401",
  ERR_NOSUCHSERVER: "402",
  ERR_NOSUCHSERVICE: "408",
  ERR_NOTEXTTOSEND: "412",
  ERR_NOTONCHANNEL: "442",
  ERR_NOTOPLEVEL: "413",
  ERR_NOTREGISTERED: "451",
  ERR_PASSWDMISMATCH: "464",
  ERR_RESTRICTED: "484",
  ERR_SERVICECONFUSED: "435",
  ERR_SERVICENAMEINUSE: "434",
  ERR_STATSKLINE: "499",
  ERR_SUMMONDISABLED: "445",
  ERR_TOOMANYCHANNELS: "405",
  ERR_TOOMANYMATCHES: "416",
  ERR_TOOMANYTARGETS: "407",
  ERR_UMODEUNKNOWNFLAG: "501",
  ERR_UNAVAILRESOURCE: "437",
  ERR_UNIQOPRIVSNEEDED: "485",
  ERR_UNKNOWNCOMMAND: "421",
  ERR_UNKNOWNMODE: "472",
  ERR_USERNOTINCHANNEL: "441",
  ERR_USERONCHANNEL: "443",
  ERR_USERSDISABLED: "446",
  ERR_USERSDONTMATCH: "502",
  ERR_WASNOSUCHNICK: "406",
  ERR_WILDTOPLEVEL: "414",
  ERR_YOUREBANNEDCREEP: "465",
  ERR_YOUWILLBEBANNED: "466",
  RPL_ADMINEMAIL: "259",
  RPL_ADMINLOC1: "257",
  RPL_ADMINLOC2: "258",
  RPL_ADMINME: "256",
  RPL_AWAY: "301",
  RPL_BANLIST: "367",
  RPL_BOUNCE: "010",
  RPL_CHANNELMODEIS: "324",
  RPL_CLOSEEND: "363",
  RPL_CLOSING: "362",
  RPL_CREATED: "003",
  RPL_ENDOFBANLIST: "368",
  RPL_ENDOFEXCEPTLIST: "349",
  RPL_ENDOFINFO: "374",
  RPL_ENDOFINVITELIST: "347",
  RPL_ENDOFLINKS: "365",
  RPL_ENDOFMOTD: "376",
  RPL_ENDOFNAMES: "366",
  RPL_ENDOFREOPLIST: "345",
  RPL_ENDOFSERVICES: "232",
  RPL_ENDOFSTATS: "219",
  RPL_ENDOFUSERS: "394",
  RPL_ENDOFWHO: "315",
  RPL_ENDOFWHOIS: "318",
  RPL_ENDOFWHOWAS: "369",
  RPL_ETRACEEND: "759",
  RPL_ETRACEFULL: "708",
  RPL_EXCEPTLIST: "348",
  RPL_GLOBALUSERS: "266",
  RPL_HELLO: "020",
  RPL_INFO: "371",
  RPL_INFOSTART: "373",
  RPL_INVITELIST: "346",
  RPL_INVITING: "341",
  RPL_ISON: "303",
  RPL_ISUPPORT: "005",
  RPL_KILLDONE: "361",
  RPL_LINKS: "364",
  RPL_LIST: "322",
  RPL_LISTEND: "323",
  RPL_LISTSTART: "321",
  RPL_LOCALUSERS: "265",
  RPL_LUSERCHANNELS: "254",
  RPL_LUSERCLIENT: "251",
  RPL_LUSERME: "255",
  RPL_LUSEROP: "252",
  RPL_LUSERUNKNOWN: "253",
  RPL_MAP: "015",
  RPL_MAPEND: "017",
  RPL_MAPSTART: "018",
  RPL_MOTD: "372",
  RPL_MOTDSTART: "375",
  RPL_MYINFO: "004",
  RPL_MYPORTIS: "384",
  RPL_NAMREPLY: "353",
  RPL_NONE: "300",
  RPL_NOTOPERANYMORE: "385",
  RPL_NOTOPIC: "331",
  RPL_NOUSERS: "395",
  RPL_NOWAWAY: "306",
  RPL_REHASHING: "382",
  RPL_REOPLIST: "344",
  RPL_SAVENICK: "043",
  RPL_SERVICE: "233",
  RPL_SERVICEINFO: "231",
  RPL_SERVLIST: "234",
  RPL_SERVLISTEND: "235",
  RPL_STATSBLINE: "247",
  RPL_STATSCLINE: "213",
  RPL_STATSCOMMANDS: "212",
  RPL_STATSDEBUG: "249",
  RPL_STATSDEFINE: "248",
  RPL_STATSDLINE: "250",
  RPL_STATSHLINE: "244",
  RPL_STATSIAUTH: "239",
  RPL_STATSILINE: "215",
  RPL_STATSKLINE: "216",
  RPL_STATSLINKINFO: "211",
  RPL_STATSLLINE: "241",
  RPL_STATSNLINE: "214",
  RPL_STATSOLINE: "243",
  RPL_STATSPING: "246",
  RPL_STATSQLINE: "217",
  RPL_STATSSLINE: "245",
  RPL_STATSUPTIME: "242",
  RPL_STATSVLINE: "240",
  RPL_STATSYLINE: "218",
  RPL_SUMMONING: "342",
  RPL_TEXT: "304",
  RPL_TIME: "391",
  RPL_TOPIC: "332",
  RPL_TOPIC_WHO_TIME: "333",
  RPL_TRACECLASS: "209",
  RPL_TRACECONNECTING: "201",
  RPL_TRACEEND: "262",
  RPL_TRACEHANDSHAKE: "202",
  RPL_TRACELINK: "200",
  RPL_TRACELOG: "261",
  RPL_TRACENEWTYPE: "208",
  RPL_TRACEOPERATOR: "204",
  RPL_TRACESERVER: "206",
  RPL_TRACESERVICE: "207",
  RPL_TRACEUNKNOWN: "203",
  RPL_TRACEUSER: "205",
  RPL_TRYAGAIN: "263",
  RPL_UMODEIS: "221",
  RPL_UNAWAY: "305",
  RPL_UNIQOPIS: "325",
  RPL_USERHOST: "302",
  RPL_USERS: "393",
  RPL_USERSSTART: "392",
  RPL_VERSION: "351",
  RPL_WELCOME: "001",
  RPL_WHOISCHANNELS: "319",
  RPL_WHOISCHANOP: "316",
  RPL_WHOISIDLE: "317",
  RPL_WHOISOPERATOR: "313",
  RPL_WHOISSERVER: "312",
  RPL_WHOISUSER: "311",
  RPL_WHOREPLY: "352",
  RPL_WHOWASUSER: "314",
  RPL_YOUREOPER: "381",
  RPL_YOURESERVICE: "383",
  RPL_YOURHOST: "002",
  RPL_YOURID: "042",
  // ChatSpace Related
  RPL_NOFLYROOM: "494",
  RPL_PASSWORDNEEDED: "512",
  RPL_INVALIDPASSWORD: "513",
  RPL_LOGGEDIN: "900",
  RPL_PASSWORDINCORRECT: "297",
  RPL_PASSWORDACCEPTED: "296",
  RPL_PRIVMSG: "PRIVMSG",
  RPL_KICK: "KICK",  
  RPL_KILL: "KILL",  
  RPL_JOIN: "JOIN",
  RPL_PART: "PART",
  RPL_QUIT: "QUIT",
  RPL_NOTICE: "NOTICE",
  RPL_WHISPER: "WHISPER",
  RPL_PING: "PING",
  RPL_MODE: "MODE",
  RPL_NICK: "NICK"
};

export default replies;