<template>
    <div class="flex-1 overflow-y-auto border-x border-red-700 text-left" id="message-box" ref="messageBoxRef">
        <ul id="chatarea" class="w-full" @click="handleClickMesssage">
            <li class="my-1 ml-1 relative" v-for="(message, index) in messages" :key="message">
                <!-- Main messages (message or gif) and Direct Messages -->
                <template v-if="isMessageVisible(message)"> 
                    <div class="flex items-start gap-1" :style="messageFontSize()">
                        <!-- Time Stamp -->
                        <p v-if="store.showTimeStamps" class="mt-1">{{ message.time }}</p>
                        
                        <!-- Avatar -->
                        <div v-if="store.enableMessageAvatar && !!message.userName" class="flex-shrink-0">
                            <img class="h-6 w-6 rounded-full border border-gray-900 object-cover hover:cursor-pointer"
                                 :class="{'border-2': message.recipient === store.userName, 'border-1': message.recipient !== store.userName}"
                                 :style="{ borderColor: getColorByGender(message.userName) }"
                                :src="getImageSource(message.userName)" alt=""
                                @click="store.toggleMessageOption(index)">
                        </div>

                        <!-- Message Content -->
                        <div class="flex-1 min-w-0">
                            <!-- Fill background color if it is direct messages between two users.-->
                            <div :style="{ backgroundColor: getMessageBackgroundColor(message) }"> 
                                <span class="font-bold cursor-pointer" @click="handleClickUserName(message.userName)">
                                    <span :style="{ color: getColorByGender(message.userName) }">{{ message.userName }}</span>:&nbsp;
                                </span>
                                <!-- Disable default message style if it is direct messages between two users -->
                                <span v-if="message.type === MSG_TYPE.MESSAGE" :class="(message.recipient?.length > 0 || message.message.includes(store.userName)) ? '' : message.style"> 
                                    <!-- Show recipient name if it is exist -->
                                    <span v-if="message.recipient?.length > 0" class="font-bold">{{ '@' + message.recipient + ": " }}</span>
                                    <span v-html="parseIRCColours(parseRoom(urlify(message.message), store.roomList))"></span>
                                    <div class="flex flex-col gap-1">
                                        <template v-for="(link, index) in extractYoutubeUrls(message.message)" :key="index">
                                            <iframe v-if="isYoutubeUrl(link)" :src="link" width="480px" height="320px"></iframe>
                                        </template>
                                    </div>
                                </span>
                            </div>
                            
                            <!-- GIF Image -->
                            <img v-if="message.type === MSG_TYPE.GIF" :src="message.message" alt="" 
                                class="w-48 h-auto cursor-pointer rounded-md mt-1" 
                                @click="showImage(message.message)">
                        </div>
                    </div>
                </template>

                <!-- Whisper messages -->
                <template v-if="isWhisper(message)">
                    <div v-if="message.userName !== 'www.ABiGChat.com'">
                        <span class="italic">
                            <span>***({{ message.direct === 'from' ? 'whisper from ' : 'whisper to ' }}</span>
                            <span class="font-bold">{{ message.userName }}</span>
                            <span>): <span v-html="parseIRCColours(parseRoom(urlify(message.message), store.roomList))"></span></span>
                        </span>
                    </div>
                    <div v-if="message.userName === 'www.ABiGChat.com'">
                        <span class="font-bold">::SERVER NOTICE:: www.ABiGChat.com: <span v-html="parseIRCColours(message.message)"></span></span>
                    </div>
                </template>
                
                <template v-if="isNotice(message)">
                    <span class="font-bold">::NOTICE:: {{ message.userName }}: <span v-html="parseIRCColours(message.message)"></span></span>
                </template>

                <!-- Action messages -->
                <template v-if="isAction(message)">
                    <div>
                        <span :class="message.style">* <span>{{ message.userName }}</span> <span v-html="parseIRCColours(message.message)"></span></span>
                    </div>
                </template>

                <!-- Quit/Part messages -->
                <template v-if="isLeaveMessage(message)">
                    <div class="flex ml-1.5">
                        <p>*** {{ message.userName }} <span v-html="parseIRCColours(message.message)"></span> {{ message.type == MSG_TYPE.PART ? message.roomName : '' }}</p>
                    </div>
                </template>

                <!-- Join messages -->
                <template v-if="isJoinMessage(message)">
                    <div class="flex ml-1.5">
                        <p>*** {{ message.userName }} <span v-html="parseIRCColours(message.message)"></span>{{ message.roomName }}</p>
                    </div>
                </template>
            </li>
        </ul>
    </div>

    <ExternalUrlWarningModal v-if="externalUrl" :url="externalUrl" @close="onCloseUrlWarningModal" />
</template>

<script setup>
/* eslint-disable no-control-regex */
/* eslint-disable no-unused-vars */

import { useMainStore } from "@/mainStore";
import { onMounted, computed, ref, watch, nextTick } from 'vue';
import { isScrollBottom, parseIRCColours, generateUniqueColorFromText, urlify, extractYoutubeUrls, parseRoom, isYoutubeUrl } from '@/helpers';
import { GENDER_FEMALE, GENDER_MALE, GENDER_OTHER, genderColors, MSG_TYPE, LINK_CLASS, ROOM_CLASS } from "@/constants";
import ExternalUrlWarningModal from "@/modals/ExternalUrlWarningModal.vue";
import { join } from "@/irc/client";

const store = useMainStore();

const messageBoxRef = ref(null); 
const messages = ref();
const externalUrl = ref();

// Message combining.
watch(
  // Array of sources to watch
  [
    () => store.joinUserMessageVisible,
    () => store.quitUserMessageVisible,
    () => store.messageHistory[store.activeRoom]?.length, 
    () => store.joinUserMessage[store.activeRoom]?.length, 
    () => store.quitUserMessage[store.activeRoom]?.length],
        () => {
            nextTick().then(() => {
                combineMessages()
            })
        },
        {
            deep: true,
            immediate: false,
        }
);

const combineMessages = () => {
    const newMessages = store.messageHistory[store.activeRoom] || [];
    const joinMessages = store.joinUserMessage[store.activeRoom] || [];
    const quitMessages = store.quitUserMessage[store.activeRoom] || [];

    // Combine all messages into a single array
    let allMessages = [...newMessages, ...joinMessages, ...quitMessages];

    // Sort the combined messages by their moment in ascending order
    allMessages.sort((a, b) => new Date(a.moment) - new Date(b.moment));

    messages.value = allMessages;

    const lastMessage = allMessages[allMessages.length - 1];
    if(isScrollBottom(messageBoxRef.value) || lastMessage.userName === store.userName || store.updateScroll) {
        nextTick(() => {
            setTimeout(() => {
                messageBoxRef.value.scrollTop = messageBoxRef.value.scrollHeight;
            }, 500);
        });
    }
}

const filteredUsers = computed(() => {
    let items = store.sidebarUserList[store.activeRoom] || [];
    const uniqueNames = new Set();
    const filtered = items.filter(item => {
        const lowerCaseName = item.userName.toLowerCase();
        if (uniqueNames.has(lowerCaseName)) {
            return false;
        } else {
            uniqueNames.add(lowerCaseName);
            return true;
        }
    });
    return filtered;
});

const showImage = (url) => {
    store.storeMessageImage(url);
    store.showImageViewer();
}

const messageFontSize = () => {
    return {
        fontSize: `${store.messageTextSize * 1.5}px`
    };
}

const getMessageBackgroundColor = (message) => {
    return ((message.recipient?.length > 0 && message.recipient === store.userName) || message.message.includes(store.userName)) ? store.directMsgBgColor : ''
}

const isMessageVisible = (message) => {
    return (message.type === MSG_TYPE.MESSAGE || message.type === MSG_TYPE.GIF);
            // (message.recipient?.length === 0 || (message.recipient?.length > 0 && (message.recipient === store.userName || message.userName === store.userName)))
}

const isAction = (message) => {
    return message.type === MSG_TYPE.ACTION;
}

const isWhisper = (message) => {
    return message.type === MSG_TYPE.WHISPER;
}

const isNotice = (message) => {
    return message.type === MSG_TYPE.NOTICE;
}

const isJoinMessage = (message) => {
    return message.type === MSG_TYPE.JOIN && store.joinUserMessageVisible;
}

const isLeaveMessage = (message) => {
    return (message.type === MSG_TYPE.QUIT || message.type === MSG_TYPE.PART) && store.quitUserMessageVisible;
}

// function to add username to message input box when it is clicked.
const handleClickUserName = (userName) => {
    // Ignore for sender
    if(store.userName == userName) return;
    store.storeRecipient(userName);
}

const getColorByGender = (userName) => {
    const users = store.sidebarUserList[store.activeRoom];    
    const user = users?.find(user => user.userName === userName || user.userName === ('@' + userName) || user.userName === ('+' + userName));
    return genderColors[user?.gender || GENDER_OTHER];
}

const getImageSource = (userName) => {
    const users = store.sidebarUserList[store.activeRoom];
    const user = users?.find(user => user.userName === userName || user.userName === ('@' + userName) || user.userName === ('+' + userName));
    if(!user) {
        return new URL('../../public/img/other.png', import.meta.url).href;
    } else {
        if(user.type === 'guest') {
            return `https://ui-avatars.com/api/?name=G&background=${generateUniqueColorFromText(userName).substring(1)}&rounded=true&size=128`
        } else {
            if (user.profilePhoto) {
                return new URL(user.profilePhoto, import.meta.url).href;
            }
            if (user.gender === GENDER_MALE) {
                return new URL('../../public/img/male.png', import.meta.url).href;
            }
            if (user.gender === GENDER_FEMALE) {
                return new URL('../../public/img/female.png', import.meta.url).href;
            }
            
            return new URL('../../public/img/other.png', import.meta.url).href;
        }
    }
};

const handleClickMesssage = (e) => {
    if (e.target.className.includes(LINK_CLASS)) {
        const url = e.target.innerText
        if (store.whitelistUrl.includes(url)) {
            window.open(url, "_blank");
        } else {
            externalUrl.value = url
        }
    } else if (e.target.className.includes(ROOM_CLASS)) {
        const roomName = e.target.innerText
        const room = store.roomList.find((room) => room.name.toLowerCase() === roomName.toLowerCase())
        if (room) {
            join(room.name)
            store.showMessageRoom();
            store.setActiveRoom(room.name);

            store.storeActiveRoomList({
                name: room.name,
                count: room.count,
                topic: room.topic,
                type: "room"
            });
            store.tabNotification[room.name] = [];
            setTimeout(() => {
                store.tabNotification[room.name] = [];
            }, 1000);
            store.hideWhisperInput()
        }
    }
}

const onCloseUrlWarningModal = () => {
    externalUrl.value = null;
}

// const privateMessage = (userName) => {
//     const users = filteredUsers.value;
//     const index = users.findIndex(user => user.userName === userName);

//     let user = {
//         name: users[index].userName,
//         gender: users[index].gender,
//         type: "user"
//     };

//     store.tabNotification[userName] = [];
//     store.storeActiveRoomList(user);
//     store.setActiveRoom(userName);
// }

// const openProfile = (userName) => {
//     const users = filteredUsers.value;
//     const index = users.findIndex(user => user.userName === userName);

//     store.toggleUserOption();
//     store.showProfileModal();
//     store.storeUserProfileData(users[index])
// }

// const onWhisper = (userName) => {
//     const users = filteredUsers.value;
//     const index = users.findIndex(user => user.userName === userName);
//     store.messageValue = '/whisper ' + userName + " "
//     store.toggleUserOption();
//     store.storeWhisperUser(users[index]);
// }

// const showReport = (userName) => {
//     const users = filteredUsers.value;
//     const index = users.findIndex(user => user.userName === userName);
//     store.showReportModal(users[index]);
// }

onMounted(() => {
    combineMessages();
});
</script>
