<script setup>
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { useMainStore } from '@/mainStore';
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';
import { sendKick } from '@/irc/client.js';

const store = useMainStore();
const reportModalRef = ref(null);


onClickOutside(reportModalRef, store.hideKickModal);

const onKick = () => {
    sendKick(store.activeRoom, store.selectedUser.userName, report.value);
    report.value = "";
    store.hideKickModal();
}

const report = ref("");
</script>


<template>
    <div class="bg-gray-400/50 absolute top-0 h-screen w-screen flex justify-center overflow-y-auto z-10" id="modal-container">
        <div class="w-[40rem] bg-white my-10 h-fit rounded-md p-4 text-black" ref="reportModalRef" id="modal">
            <div class="flex justify-between items-center px-4 py-5">
                <p class="text-2xl">Kick User @{{ store.selectedUser.userName }}</p>
                <XMarkIcon class="h-6 w-6 text-gray-500 hover:text-gray-800 cursor-pointer"
                    @click="store.hideKickModal" />
            </div>
            <hr>
            <form @submit.prevent="onKick">

                <div>
                    <p class="my-2 text-sm text-left">Please provide the reason why you are kicking this user.</p>
                    <textarea rows="4" name="comment" id="comment" v-model="report" placeholder="Type your message here..." class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm px-2 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
                <div class="py-2">
                    <button type="submit" class="bg-custom-secondary w-full py-2 rounded-md my-1">Kick</button>
                </div>
            </form>
            <hr>
            <div class="py-4">
                <button class="bg-custom-secondary w-full py-2 rounded-md my-1 text-white" @click="store.hideKickModal">Close</button>
            </div>
        </div>
    </div>
</template>
