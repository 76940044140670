<template>
    <ul class="rounded-xl shadow-popup bg-white space-y-1" v-if="store.commandPickerVisible" ref="commandPicker">
        <li v-for="command in filteredCommands" :key="command.command" @click="handleChooseCommand(command)" class="hover:bg-gray-100 cursor-pointer">
            <div class="px-2 py-1 flex items-center gap-2">
                <div v-if="command.alias" class="text-sm p-0.5 bg-gray-300 rounded-md">{{ command.alias }}</div>
                <div class="text-sm p-0.5 bg-gray-300 rounded-md">{{ command.format }}</div>
                <p>{{ command.description }}</p>
            </div>
        </li>
    </ul>
</template>

<script setup>
import { commands } from '@/constants';
import { isModUser } from '@/helpers';
import { useMainStore } from '@/mainStore';
import { onClickOutside } from '@vueuse/core'
import { ref, watch, defineEmits} from 'vue';

const emit = defineEmits(['choose'])

const store = useMainStore();

const commandPicker = ref(null)
const filteredCommands = ref([]);

onClickOutside(commandPicker, store.hideCommandPicker);

watch(() => store.messageValue, () => {
    const isMod = isModUser(store)
    const isGuest = store.userName.startsWith("G|")
    filteredCommands.value = store.messageValue.length > 0 ?
        commands.filter((cmd) => (!cmd.moderator || isMod) && (!cmd.guest || isGuest) && (cmd.command.startsWith(store.messageValue) || cmd.alias?.startsWith(store.messageValue))) : []
})

watch(() => filteredCommands.value.length, () => {
    if (filteredCommands.value.length) {
        store.showCommandPicker();
    } else {
        store.hideCommandPicker();
    }
})

const handleChooseCommand = (command) => {
    emit('choose', command)
}
</script>
