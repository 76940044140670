<template>
  <div class="flex flex-col" ref="containerDiv">
    <vue3-confirm-dialog/>
    <IRCClient v-if="!store.navbarAtBottom" />
    <LoginModal v-if="store.loginModal" />
    <!-- <RegisterModal v-if="store.registerModal" /> -->
    <PasswordModal v-if="store.passwordModal" />
    <Transition>
      <RoomListModal v-if="store.roomListModal && !store.errMessage.length" class="z-10" />
    </Transition>
    <Transition>
      <BroadcastingPendingRequestModal v-if="store.showBroadcastPendingRequestsModal" class="z-10" />
    </Transition>
    <Transition>
      <BroadcastWaiting v-if="store.broadcastWaiting" class="z-10" />
    </Transition>
    <MessageModal v-if="store.messageModal && store.activeRoomList.length"/>
    <Transition>
      <OptionModal v-if="store.optionModal" class="z-10" />
    </Transition>
    <Transition>
      <FontColorModal v-if="store.fontModal" class="z-10" />
    </Transition>
    <Transition>
      <Conversation v-if="store.conversationModal" class="z-10" />
    </Transition>
    <Transition>
      <ReportModal v-if="store.reportModal" class="z-10" />
    </Transition>
    <Transition>
      <ReportIncident v-if="store.reportIncidentModal" class="z-10" />
    </Transition>
    <HelpModal v-if="store.helpModal" />
    <Transition>
      <ShareModal v-if="store.shareModal" class="z-10" />
    </Transition>
    <Transition>
      <ProfileModal v-if="store.profileModal" class="z-10" />
    </Transition>
    <Transition>
      <gifModal v-if="store.gifModal" class="z-10" />
    </Transition>
    <Transition>
      <imageViewer v-if="store.imageViewerModal" class="z-10" />
    </Transition>
    <Transition>
      <KickModal v-if="store.visibleKickModal" class="z-10" />
    </Transition>
    <Transition>
      <ErrorModal v-if="store.errMessage.length > 0" class="z-10" />
    </Transition>
    <Transition>
      <KilledModal v-if="store.killedMessage.length > 0" class="z-10" />
    </Transition>
  </div>
</template>

<script setup>
import IRCClient from './components/IRCClient.vue';
import LoginModal from '@/modals/LoginModal.vue';
import PasswordModal from '@/modals/PasswordModal.vue'
// import RegisterModal from './modals/RegisterModal.vue';
import MessageModal from '@/modals/MessageModal.vue';
import ErrorModal from '@/modals/ErrorModal.vue';
import KilledModal from '@/modals/KilledModal.vue';
import OptionModal from './modals/OptionModal.vue';
import FontColorModal from './modals/FontColorModal.vue'
import Conversation from './modals/Conversation.vue'
import ShareModal from './modals/ShareModal.vue'
import { onMounted, onUnmounted, ref } from 'vue';
import { useMainStore } from './mainStore';
import RoomListModal from './modals/RoomListModal.vue';
import ReportModal from './modals/ReportModal.vue';
import HelpModal from './modals/HelpModal.vue';
import gifModal from './modals/gifModal.vue';
import KickModal from './modals/KickModal.vue';
import { io } from "socket.io-client";
import ReportIncident from './modals/ReportIncident.vue';
import ProfileModal from './modals/ProfileModal.vue';
import imageViewer from './modals/imageViewer.vue';
import BroadcastingPendingRequestModal from './modals/BroadcastingPendingRequestModal.vue';
import BroadcastWaiting from './modals/BroadcastWaiting.vue';
import WebRTC from './irc/webrtc';
import { approveBroadcastEntry } from "./irc/broadcaster";

const store = useMainStore()

const socket = io(store.config.socketHost);
store.addSocket(socket);

const handleEscClick = (event) => {
  if (event.key === 'Escape') {
    store.hideOptionModal();
    store.hideMenuList();
    store.hideRoomList();
    store.hideEmojiSelector();
    store.hideFontModal();
    store.hideReportModal();
    store.hideReportIncidentModal();
    store.hideConversationModal()
    store.hideGifModal()
    store.hideImageViewer()
    store.toggleUserOption(null);
    store.hideProfileModal(null);
  }
}

store.socket.on('messageStyle', (message) => {
  store.storeSocketMessage(message);
})

//Video related event handlers - start
store.socket.on('broadcastingUsers', (broadcastingUsers) => {
  console.log("Updating broadcasting users", broadcastingUsers);
  store.setBroadcastingUsers(broadcastingUsers);
  
  if(!broadcastingUsers.includes(store.activeBroadcaster)) {
    if (store.activeBroadcaster && store.peers[store.activeBroadcaster]) {
        store.peers[store.activeBroadcaster].close();
        store.removePeer(store.activeBroadcaster);
        store.deleteRemoteStream();
        store.removeActiveBroadcaster();
    }
  }
});


store.socket.on('requestEntry', (data) => {
  console.log("requestEntry", data);
  if (data.to != store.userName) {
    return;
  }

  store.addToPendingPermission(data.from);

  if(store.privateBroadcasting) {
    store.showBroadcastPendingRequests();
  }
  else {
    approveBroadcastEntry(data.from);
  }
});

store.socket.on("offer", (data) => {
  if (data.to != store.userName) {
    return;
  }

  const connection = new WebRTC(data.from);
  connection.handleOffer(data);
});

store.socket.on("answer", (data) => {
  if (data.to != store.userName) {
    return;
  }

  WebRTC.handleAnswer(data);
});

store.socket.on("candidate", (data) => {
  if(data.to != store.userName){
    return;
  }

  WebRTC.handleCandidate(data);
});

store.socket.on("declined", (data) => {
  if(data.to != store.userName){
    return;
  }

  store.setBroadcastWaiting(false);
});
//Video related event handlers - end
const containerDiv = ref(null);

// Define the resize event handler as a separate function so it can be easily added and removed
const adjustHeight = () => {
  if (containerDiv.value) {
    containerDiv.value.style.height = `${window.innerHeight}px`;
    containerDiv.value.style.minHeight = `${window.innerHeight}px`;
  }
};

const handleResize = () => {
  adjustHeight(); // Adjust the minHeight on resize
  if (window.innerWidth <= 640) {
    store.hideSideBar();
  } else {
    store.showSideBar();
  }
  if (window.innerWidth <= 1280) {
    store.hideExtendedRoomList();
  } else {
    store.showExtendedRoomList();
  }
};

// Setup event listeners
onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
  document.addEventListener('keyup', handleEscClick);
});

// Cleanup resize event listeners
onUnmounted(() => {
  window.removeEventListener('resize', handleResize); 
  document.removeEventListener('keyup', handleEscClick);
});
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
