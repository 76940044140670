import { defineStore } from "pinia";
import { readLocalStorage, writeLocalStorage } from "./helpers";
import { STORAGE_BACKGROUND, STORAGE_FOREGROUND, STORAGE_JOIN_USER, STORAGE_QUIT_USER } from "./constants";

const initialJoinUser = readLocalStorage(STORAGE_JOIN_USER) === "false" ? false : true
const initialQuitUser = readLocalStorage(STORAGE_QUIT_USER) === "false" ? false : true
const initialForeground = readLocalStorage(STORAGE_FOREGROUND) ?? "text-[#000]"
const initialBackground = readLocalStorage(STORAGE_BACKGROUND) ?? "bg-[#fff]"

export const useMainStore = defineStore("main", {
  state: () => ({
    config: {
      apiHost: process.env.VUE_APP_API_URL,
      socketHost: process.env.VUE_APP_SOCKET_URL,
    },

    bgPrimary: '#AE0900',
    bgSecondary: '#E92322',

    roomList: [],
    userList: [],
    activeRoomList: [],
    socketUserList: [],
    gender: '',
    remoteStream: null,

    //Broadcasting related state variables
    broadcasting: false,
    privateBroadcasting: true,
    activeBroadcaster: null,
    peerCount: 0,
    peers: {},
    localVideo: null,
    localStream: null,
    broadcastingUsers: [],
    showBroadcastPendingRequestsModal: false,
    pendingPermissions: [],
    broadcastWaiting: false,

    selectedRoom: null,
    userName: null,
    recipient: '',
    activeRoom: '',
    socket: '',
    userDetail: {},
    visibleActionMessage: false,
    actionMessage: '',
    whisperMessage: '',
    messageImageUrl: '',
    menuList: false,
    loginModal: true,
    passwordModal: false,
    registerModal: false,
    roomListModal: false,
    messageModal: false,
    optionModal: false,
    fontModal: false,
    reportModal: false,
    reportIncidentModal: false,
    shareModal: false,
    helpModal: false,
    conversationModal: false,
    emojiModal: false,
    profileModal: false,
    gifModal: false,
    imageViewerModal: false,
    commandPickerVisible: false,
    sideBar: true,
    enablePrivateMessage: true,
    updateScroll: true,
    sideBarInLeft: false,
    navbarAtBottom: false,
    expandActiveRoomList: true,
    enableUserListAvatar: true,
    enableMessageAvatar: true,
    showTimeStamps: false,
    notificationOnTab: true,
    joinUserMessageVisible: initialJoinUser,
    quitUserMessageVisible: initialQuitUser,
    sortUserListAtoZ: true,
    sortUserByGender: false,
    blockMaleUser: false,
    blockFemaleUser: false,
    blockOtherUser: false,
    blockWhisper: false,
    boldMessage: false,
    underlineMessage: false,
    italicMessage: false,
    isColorActive: true,
    notificationSound: false,
    vibrationOnMessage: true,
    whisperInput: false,
    visibleProfileOption: false,
    visibleProfilePhoto: false,
    visibleUploadProfilePhoto: false,
    visibleSendPhotoModal: false,
    visibleBroadcastOption: false,
    isAudioDisable: false,
    isVideoDisable: false,
    isForceLogout: false,
    muted: false,
    shareCamera: true,
    messageValue:'',
    profilePhoto: '',
    profilePhotoFile: '',
    userOption: '',
    messageOption: -1,
    roomType: '',
    selectedUser: '',
    activeTab: 'private',
    messageTextSize: '10',
    BackgroundColor: initialBackground,
    textColor: initialForeground,
    messageStyleBold: '',
    messageStyleUnderline: '',
    messageStyleItalic: '',
    messageImage: '',
    isLoggedIn: true,
    isButtonDisable: false,
    selectedGif: null,
    allUserList: [],
    userProfile: [],
    whisperUser: [],

    passwordError: null,
    socketMessage: [],

    messageHistory: {},
    sidebarUserList: {},
    quitUserMessage: {},
    joinUserMessage: {},
    tabNotification: {},
    userLength: '',

    banList: [],
    whitelistUrl: [],

    directMsgBgColor: '#d9ecff',
    errTitle: '',
    errMessage: '',
    visibleKickModal: false,
    killedMessage: '',
  }),
  actions: {
    setBroadcastWaiting(isWaiting) {
      this.broadcastWaiting = isWaiting
    },
    addToPendingPermission(user) {
      if (this.pendingPermissions.indexOf(user) < 0) {
        this.pendingPermissions.push(user);
      }
    },
    removeFromPendingPermission(user) {
      let userIndex = this.pendingPermissions.indexOf(user);
      if (userIndex > -1) {
        this.pendingPermissions.splice(userIndex, 1);
        if (this.pendingPermissions.length == 0) {
          this.hideBroadcastPendingRequests();
        }
      }
    },
    setRemoteStream(stream) {
      this.remoteStream = stream;
      var remoteVideo = document.getElementById("remote-video-holder");

      if (stream) {
        remoteVideo.srcObject = stream;
        var playPromise = remoteVideo.play();

        if (playPromise !== undefined) {
          playPromise.then(() => {
            console.log("Remote video playing")
          })
            .catch(error => {
              console.error(error);
            });
        }
      } else if (remoteVideo.srcObject) {
        remoteVideo.srcObject.getTracks().forEach(function (track) {
          track.stop();
        });
      }
    },
    deleteRemoteStream() {
      this.remoteStream = null;
      var remoteVideo = document.getElementById("remote-video-holder");
      if (remoteVideo.srcObject) {
        remoteVideo.srcObject.getTracks().forEach(function (track) {
          track.stop();
        });
      }
    },
    setActiveBroadcaster(broadcaster) {
      this.activeBroadcaster = broadcaster;
    },
    removeActiveBroadcaster() {
      this.activeBroadcaster = null;
    },
    addPeer(peer) {
      this.peerCount++;
      this.peers[peer.nickname] = peer.connection;
    },
    removePeer(nickname) {
      this.peerCount--;
      delete this.peers[nickname];
    },
    setBroadcastingUsers(broadcastingUsers) {
      this.broadcastingUsers = broadcastingUsers;
    },
    setLocalStream(stream) {
      this.localStream = stream;
    },
    setLocalVideo(lv) {
      this.localVideo = lv;
    },
    enableBroadcasting() {
      this.broadcasting = true;
    },
    disableBroadcasting() {
      this.broadcasting = false;
    },
    enablePrivateBroadcasting() {
      this.privateBroadcasting = true;
    },
    enablePublicBroadcasting() {
      this.privateBroadcasting = false;
    },
    toggleBroadcaster() {
      this.broadcasting = !this.broadcasting;
    },
    addActiveJoin(room) {
      this.activeRoomList.push(room);
    },
    removeActiveRoom(room) {
      var index = this.activeRoomList.indexOf(room);
      if (index !== -1) {
        this.activeRoomList.splice(index, 1);
      }
    },
    removeRoomList(room) {
      var index = this.activeRoomList.findIndex(obj => obj.name === room.name);
      if (index !== -1) {
        this.activeRoomList.splice(index, 1);
      }
    },
    removeRoomListByName(roomName) {
      var index = this.activeRoomList.findIndex(obj => obj.name === roomName);
      if (index !== -1) {
        this.activeRoomList.splice(index, 1);
      }
    },
    clearRoomList() {
      this.activeRoomList = [];
    },
    showRegisterModal() {
      this.registerModal = true;
    },
    hideRegisterModal() {
      this.registerModal = false;
    },
    showCommandPicker() {
      this.commandPickerVisible = true;
    },
    hideCommandPicker() {
      this.commandPickerVisible = false;
    },
    setIsLoggedIn(val) {
      this.isLoggedIn = val;
    },
    setLoginModal(shown) {
      this.loginModal = shown;
    },
    hideLoginModal() {
      this.loginModal = false;
    },
    showLoginModal() {
      this.loginModal = true;
    },
    setPasswordModal(shown) {
      this.passwordModal = shown;
    },
    setPasswordError(message) {
      this.passwordError = message;
    },
    setRooms(rooms) {
      this.roomList = rooms;
    },
    showBroadcastPendingRequests() {
      this.showBroadcastPendingRequestsModal = true;
    },
    hideBroadcastPendingRequests() {
      this.showBroadcastPendingRequestsModal = false;
    },
    showRoomList() {
      this.roomListModal = true;
    },
    hideRoomList() {
      this.roomListModal = false;
    },
    showMessageRoom() {
      this.messageModal = true;
    },
    hideMessageRoom() {
      this.messageModal = false;
    },
    showSideBar() {
      this.sideBar = true;
    },
    hideSideBar() {
      this.sideBar = false;
    },
    showShareModal() {
      this.shareModal = true;
    },
    hideShareModal() {
      this.shareModal = false;
    },
    showImageViewer() {
      this.imageViewerModal = true;
    },
    hideImageViewer() {
      this.imageViewerModal = false;
    },
    setForceLogout() {
      this.isForceLogout = true;
    },
    disableForceLogout() {
      this.isForceLogout = false;
    },
    storeRoomName(storeRoomName) {
      this.selectedRoom = storeRoomName;
    },
    storeUserName(userName) {
      this.userName = userName
    },
    storeRecipient(recipientName) {
      this.recipient = recipientName;
    },
    addUserList(detail) {
      this.userList.push(detail)
    },
    addMessage(message) {
      const currentTimeUTCString = new Date().toISOString();
      if (this.messageHistory[message.room]) {
        this.messageHistory[message.room].push({ ...message, moment: currentTimeUTCString});
      }
      else {
        this.messageHistory[message.room] = []
        this.messageHistory[message.room].push({ ...message, moment: currentTimeUTCString});
      }
      this.recipient = '';
    },
    clearUser() {
      this.sidebarUserList[this.activeRoom] = [];
    },
    addUser(userDetail) {
      if (this.sidebarUserList[userDetail.room]) {
        // Check if it is moderator
        const existIndex = this.sidebarUserList[userDetail.room].findIndex(user => user.userName === userDetail.userName || ('@' + user.userName) === userDetail.userName || user.userName === ('+' + userDetail.userName));
        if(existIndex !== -1) {
          this.sidebarUserList[userDetail.room][existIndex] = userDetail;
        } else {
          this.sidebarUserList[userDetail.room].push(userDetail);
        }
      }
      else {
        this.sidebarUserList[userDetail.room] = []
        this.sidebarUserList[userDetail.room].push(userDetail);
      }
    },
    increaseRoomUserCount(roomName) {
      const room = this.roomList.find(item => item.name === roomName);
      if(room) room.count = Number(room.count) + 1;
    },
    decreaseRoomUserCount(roomName) {
      const room = this.roomList.find(item => item.name === roomName);
      if(room && room?.count > 0) room.count = Number(room.count) - 1;
    },
    removeRoomUser(userName, room) {
      if(this.sidebarUserList[room]?.length > 0) {
        this.sidebarUserList[room] = this.sidebarUserList[room].filter(item => item.userName !== userName);
      }
    },
    removeUserFromAllRooms(userName) {
      for (let key in this.sidebarUserList) {
        if (Object.prototype.hasOwnProperty.call(this.sidebarUserList, key)) {
          this.sidebarUserList[key] = this.sidebarUserList[key].filter(item => item.userName !== userName);
        }
      }
    },
    storeActiveRoomList(room) {
      const roomExist = this.activeRoomList.find(user => user.name == room.name);
      if (!roomExist) {
        this.activeRoomList.push(room)
      }
    },
    toggleMenuList() {
      this.menuList = !this.menuList;
    },
    showMenuList() {
      this.menuList = true;
    },
    hideMenuList() {
      this.menuList = false;
    },
    setActiveRoom(room) {
      this.activeRoom = room;
    },
    toggleUserOption(userName) {
      if (this.userOption === userName) {
        this.userOption = "";
      } else {
        this.userOption = userName;
      }
    },
    toggleMessageOption(messageIndex) {
      if(this.messageOption == messageIndex) {
        this.messageOption = -1;
      } else {
        this.messageOption = messageIndex;
      }
    },
    togglePrivateMessage() {
      this.enablePrivateMessage = !this.enablePrivateMessage;
    },
    showOptionModal() {
      this.optionModal = true;
    },
    hideOptionModal() {
      this.optionModal = false;
    },
    showFontModal() {
      this.fontModal = true;
    },
    hideFontModal() {
      this.fontModal = false;
    },
    showProfileModal() {
      this.profileModal = true;
    },
    hideProfileModal() {
      this.profileModal = false;
      this.hideProfileOption()
    },
    showReportModal(user) {
      this.reportModal = true;
      this.selectedUser = user;
    },
    hideReportModal() {
      this.reportModal = false;
    },
    showReportIncidentModal(user) {
      this.reportIncidentModal = true;
      this.selectedUser = user;
    },
    hideReportIncidentModal() {
      this.reportIncidentModal = false;
    },
    showHelpModal() {
      this.helpModal = true;
    },
    hideHelpModal() {
      this.helpModal = false;
    },
    showGifModal() {
      this.gifModal = true;
    },
    hideGifModal() {
      this.gifModal = false;
    },
    setActiveOption(tab) {
      this.activeTab = tab;
    },
    toggleSideBarPosition() {
      this.sideBarInLeft = !this.sideBarInLeft
    },
    toggleUpdateScroll() {
      this.updateScroll = !this.updateScroll
    },
    toggleNavbarPosition() {
      this.navbarAtBottom = !this.navbarAtBottom
    },
    toggleExtendedRoomList() {
      this.expandActiveRoomList = !this.expandActiveRoomList
    },
    hideExtendedRoomList() {
      this.expandActiveRoomList = false
    },
    showExtendedRoomList() {
      this.expandActiveRoomList = true
    },
    toggleUserListAvatar() {
      this.enableUserListAvatar = !this.enableUserListAvatar
    },
    toggleMessageAvatar() {
      this.enableMessageAvatar = !this.enableMessageAvatar
    },
    toggleTimestampsVisible() {
      this.showTimeStamps = !this.showTimeStamps;
    },
    setMessageTextSize(size) {
      this.messageTextSize = size;
    },
    addJoinUserMessage(UserDetail) {
      const currentTimeUTCString = new Date().toISOString();
      if (this.joinUserMessage[UserDetail.roomName]) {
        this.joinUserMessage[UserDetail.roomName].push({ ...UserDetail, moment: currentTimeUTCString})
      }
      else {
        this.joinUserMessage[UserDetail.roomName] = []
        this.joinUserMessage[UserDetail.roomName].push({ ...UserDetail, moment: currentTimeUTCString})
      }
    },
    addQuitUserMessage(UserDetail) {
      const currentTimeUTCString = new Date().toISOString();
      if (this.quitUserMessage[UserDetail.roomName]) {
        this.quitUserMessage[UserDetail.roomName].push({ ...UserDetail, moment: currentTimeUTCString})
      }
      else {
        this.quitUserMessage[UserDetail.roomName] = []
        this.quitUserMessage[UserDetail.roomName].push({ ...UserDetail, moment: currentTimeUTCString})
      }
    },
    toggleJoinUserMessage() {
      this.joinUserMessageVisible = !this.joinUserMessageVisible;
      writeLocalStorage(STORAGE_JOIN_USER, this.joinUserMessageVisible)
    },
    toggleQuitUserMessage() {
      this.quitUserMessageVisible = !this.quitUserMessageVisible;
      writeLocalStorage(STORAGE_QUIT_USER, this.quitUserMessageVisible)
    },
    toggleEmojiSelector() {
      this.emojiModal = !this.emojiModal;
    },
    hideEmojiSelector() {
      this.emojiModal = false;
    },
    toggleUserListSort() {
      this.sortUserListAtoZ = !this.sortUserListAtoZ;
    },
    setUserSort() {
      this.sortUserByGender = !this.sortUserByGender;
    },
    setBackgroundColor(color) {
      this.BackgroundColor = color;      
      writeLocalStorage(STORAGE_BACKGROUND, color);
    },
    setTextColor(color) {
      this.textColor = color;
      writeLocalStorage(STORAGE_FOREGROUND, color);
    },
    setMessageStyleBold() {
      if (this.messageStyleBold == '') {
        this.messageStyleBold = 'font-bold'
        this.boldMessage = true;
      } else {
        this.messageStyleBold = ''
        this.boldMessage = false;
      }
    },
    setMessageStyleUnderline() {
      if (this.messageStyleUnderline == '') {
        this.messageStyleUnderline = 'underline';
        this.underlineMessage = true;
      } else {
        this.messageStyleUnderline = '';
        this.underlineMessage = false;
      }
    },
    setMessageStyleItalic() {
      if (this.messageStyleItalic == '') {
        this.messageStyleItalic = 'italic';
        this.italicMessage = true;
      } else {
        this.messageStyleItalic = '';
        this.italicMessage = false;
      }
    },
    showConversationModal() {
      this.conversationModal = true;
    },
    hideConversationModal() {
      this.conversationModal = false;
    },
    setColorActiveFalse() {
      this.isColorActive = false;
    },
    setColorActiveTrue() {
      this.isColorActive = true;
    },
    toggleColorActive() {
      this.isColorActive = !this.isColorActive;
      if (!this.isColorActive) {
        this.BackgroundColor = "bg-[#fff]"
        this.textColor = "text-[#000]"
        writeLocalStorage(STORAGE_FOREGROUND, this.textColor);
        writeLocalStorage(STORAGE_BACKGROUND, this.BackgroundColor);
      }
    },
    addSocket(socket) {
      this.socket = socket
    },
    storeUserDetail(data) {
      this.userDetail = data;
    },
    cleanSocketUserList() {
      this.socketUserList = [];
    },
    setSocketUserList(user) {
      this.socketUserList.push(user);
    },
    storeGender(gender) {
      this.gender = gender;
    },
    toggleBlockMaleUser() {
      this.blockMaleUser = !this.blockMaleUser;
    },
    toggleBlockFemaleUser() {
      this.blockFemaleUser = !this.blockFemaleUser;
    },
    toggleBlockOtherUser() {
      this.blockOtherUser = !this.blockOtherUser;
    },
    toggleNotificationSound() {
      this.notificationSound = !this.notificationSound
    },
    toggleVibration() {
      this.vibrationOnMessage = !this.vibrationOnMessage
    },
    storeActionMessage(message) {
      this.actionMessage = message;
    },
    showActionMessage() {
      this.visibleActionMessage = true;
    },
    hideActionMessage() {
      this.visibleActionMessage = false;
    },
    enableButton() {
      this.isButtonDisable = false;
    },
    disableButton() {
      this.isButtonDisable = true;
    },
    storeNewUser(user) {
      this.allUserList.push(user);
    },
    storeUserProfileData(user) {
      this.userProfile = user;
    },
    toggleWhisper() {
      this.blockWhisper = !this.blockWhisper;
    },
    showWhisperInput() {
      this.whisperInput = true;
    },
    hideWhisperInput() {
      this.whisperInput = false;
    },
    storeWhisperUser(user) {
      this.whisperUser = user;
    },
    toggleProfileOption() {
      this.visibleProfileOption = !this.visibleProfileOption
    },
    showProfileOption() {
      this.visibleProfileOption = true
    },
    hideProfileOption() {
      this.visibleProfileOption = false
    },
    showProfilePhoto() {
      this.visibleProfilePhoto = true
    },
    hideProfilePhoto() {
      this.visibleProfilePhoto = false
    },
    showUploadProfilePhoto() {
      this.visibleUploadProfilePhoto = true
    },
    hideUploadProfilePhoto() {
      this.visibleUploadProfilePhoto = false
    },
    storeProfilePhoto(img) {
      this.profilePhoto = img;
    },
    storeProfilePhotoFile(file) {
      this.profilePhotoFile = file;
    },
    toggleNotificationOnTab() {
      this.notificationOnTab = !this.notificationOnTab;
    },
    storeTabNotification(noti) {
      if (this.tabNotification[noti.room]) {
        this.tabNotification[noti.room].push(noti);
      }
      else {
        this.tabNotification[noti.room] = []
        this.tabNotification[noti.room].push(noti);
      }
    },
    storeSocketMessage(message) {
      this.socketMessage.push(message);
    },
    storeSelectedGif(img) {
      this.selectedGif = img;
    },
    storeMessageImage(url) {
      this.messageImage = url;
    },
    setListLength(length) {
      this.userLength = length;
    },
    setImageUrl(image) {
      this.messageImageUrl = image;
    },
    showimageSender() {
      this.visibleSendPhotoModal = true;
    },
    hideimageSender() {
      this.visibleSendPhotoModal = false;
    },
    toggleBroadcastOption() {
      this.visibleBroadcastOption = !this.visibleBroadcastOption
    },
    hideBroadcastOption() {
      this.visibleBroadcastOption = false;
    },
    enableAudioButton() {
      this.isAudioDisable = false;
    },
    disableAudioButton() {
      this.isAudioDisable = true;
    },
    enableVideoButton() {
      this.isVideoDisable = false;
    },
    disableVideoButton() {
      this.isVideoDisable = true;
    },
    muteAudio() {
      this.muted = true;
    },
    unmuteAudio() {
      this.muted = false;
    },
    startShearingCamera() {
      this.shareCamera = true;
    },
    stopShearingCamera() {
      this.shareCamera = false;
    },
    setDirectMsgBgColor(color) {
      this.directMsgBgColor = color;
    },
    showErrorModal(title, message) {
      this.errMessage = message;
      this.errTitle = title
    },
    hideKickedModal() {
      this.errTitle = '';
      this.errMessage = '';
    },    
    showKickModal(user) {
      this.visibleKickModal = true;
      this.selectedUser = user;
    },
    hideKickModal() {
      this.visibleKickModal = false;
    },
    showKilledModal(message) {
      this.killedMessage = message;
    },
    hideKilledModal() {
      this.killedMessage = '';
      window.location.reload();
    },
    clearRoomMessages(room) {
      this.messageHistory[room] = [];
      this.joinUserMessage[room] = [];
      this.quitUserMessage[room] = [];
    },
    clearMessageHistory() {
      this.messageHistory = {};
    },
    addToBanList(user, room) {
      if (!this.banList.find((v) => v.user === user && v.room === room)) {
        this.banList.push({user, room});
      }
    },
    removeFromBanList(user) {
      this.banList = this.banList.filter((v) => v.user === user);
    },
    addToWhitelistUrl(url) {
      this.whitelistUrl.push(url);
    },
  },
});
